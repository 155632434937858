import React from 'react'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Button from '@material-ui/core/Button'

function Logout(props) {
    return !!props.customerName
        ? (
            <Button
                variant="outlined"
                endIcon={<LockOpenIcon/>}
                onClick={(e) => props.handleLogoutClick(e)}>
                Logout
            </Button>
        )
        : (
            <div></div>
        )
}

export default Logout
