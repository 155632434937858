import React from 'react'
import './LoggedInCustomer.css'
import PersonIcon from '@material-ui/icons/Person'
import Logout from './Logout'

function LoggedInCustomer(props) {
    if (!!props.customerName) {
        return (
            <div className="logged-in-container">
                <div className="logged-in"><strong>{props.customerName}</strong> <PersonIcon/></div>
                <Logout customerName={props.customerName} handleLogoutClick={props.handleLogoutClick}/>
            </div>
        )
    }
    return (
        <div className="logged-in"></div>
    )
}

export default LoggedInCustomer
