import './FormComponent.css'
import React from 'react';
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

function FormComponent(props) {
    return (
        <main>
            <h2>Search for certificates</h2>
            <form>
                <TextField
                    name="skuNO"
                    className="form-control"
                    size={'small'}
                    value={props.skuNO}
                    onChange={event => props.setSkuNO(event.target.value)}
                    placeholder="SKU NO"
                    aria-label="SKU NO"
                    label="SKU NO"
                    variant="outlined"/>
                <TextField
                    name="purchaseOrder"
                    className="form-control"
                    size={'small'}
                    value={props.purchaseOrder}
                    onChange={event => props.setPurchaseOrder(event.target.value)}
                    placeholder="PURCHASE ORDER #"
                    aria-label="PURCHASE ORDER #"
                    label="PURCHASE ORDER"
                    variant="outlined"/>
                <TextField
                    name="description"
                    className="form-control"
                    size={'small'}
                    value={props.description}
                    onChange={event => props.setDescription(event.target.value)}
                    placeholder="DESCRIPTION"
                    aria-label="DESCRIPTION"
                    label="DESCRIPTION"
                    variant="outlined"/>
                <Button
                    disableRipple={true}
                    disabled={
                        props.description === '' &&
                        props.skuNO === '' &&
                        props.purchaseOrder === ''
                    }
                    size="large"
                    variant="text"
                    color="default"
                    startIcon={!props.loading ? <SearchIcon/> :
                        <CircularProgress thickness={6} size={22} color="primary"/>}
                    onClick={(e) => {
                        e.preventDefault();
                        props.handleSearchClick(props.skuNO, props.purchaseOrder, props.description);
                    }}>
                    SEARCH
                </Button>
            </form>
        </main>
    )
}

export default FormComponent
