import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import FormContainer from './FormContainer'
import SearchResultContainer from './SearchResultContainer'
import Header from './Header'
import dataColumns from '../data/dataColumns'
import dataRows from '../data/dataRows'
import customerIdNames from '../data/customerIdNamesPairs'

function Search() {

    const [rows, setRows] = useState([])
    const [customerName, setCustomerName] = useState('')
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    const history = useHistory();

    useEffect(() => {
        // todo: call endpoint and set customerName
        try {
            setTimeout(() => {
                const customerIdNamePair = customerIdNames.find((value) => value.id === id)
                !!customerIdNamePair ? setCustomerName(customerIdNamePair.name) : history.push(`/logged-out/${1}`)
            }, 3000)
        }
        catch (e) {
            history.push(`/logged-out/${2}`)
        }

    }, [id])

    useEffect(() => {
        localStorage.setItem('customerName', customerName)
    }, [customerName])

    function handleLogoutClick(event) {
        event.preventDefault()
        setCustomerName('')
        history.push(`/logged-out/${0}`)
    }

    function handleSearchClick(skuNO, purchaseOrder, description) {
        setLoading(true)
        if (customerName) {
            // todo: call endpoint and set rows
            setTimeout(() => {
                setRows(dataRows)
                setLoading(false)
            }, 3000)
        }
    }

    return (
        <div>
            <Header customerName={customerName} handleLogoutClick={handleLogoutClick}/>
            {
                !!customerName && <FormContainer handleSearchClick={handleSearchClick} loading={loading}/>
            }
            {
                (rows.length > 0 && !!customerName) &&
                <SearchResultContainer pageSize={5} columns={dataColumns} rows={rows} checkboxSelection={true}/>
            }
        </div>
    )
}

export default Search
