import './SearchResult.css'
import React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

function SearchResult(props) {
    return (
        <div>
            <div className="download-container">
                <Button
                    size="large"
                    disabled={props.disabled}
                    aria-label="download"
                    onClick={props.onDownloadClick}
                    variant="text"
                    color="default"
                    startIcon={!props.loading ? <SaveIcon /> : <CircularProgress thickness={6} size={22} color="primary" />}>
                    DOWNLOAD
                </Button>
            </div>
            <div className="data-container">
                <DataGrid
                    rows={props.rows}
                    columns={props.columns}
                    pageSize={props.pageSize}
                    loading={props.loading}
                    autoHeight={true}
                    paginationMode={'client'}
                    checkboxSelection={props.checkboxSelection}
                    onSelectionChange={props.onSelectionChange} />
            </div>
        </div>
    )
}

export default SearchResult

