import React from 'react'
import LoggedInCustomer from './LoggedInCustomer'
import Customer from './Customer'
import Logo from './Logo'

function Header(props) {
    return (
        <header className="App-header">
                <Logo/>
                <div className="customer-info">
                    <Customer customerName={props.customerName}/>
                    <LoggedInCustomer customerName={props.customerName} handleLogoutClick={props.handleLogoutClick}/>
                </div>
        </header>
    )
}

export default Header
