const customerIdNamesPairs = [
    {
        id: 'QNFTMGQXLL',
        name: 'Zalando'
    },
    {
        id: 'C2L19J6I9B',
        name: 'Zalando1'
    },
    {
        id: 'TLJFSQ6L26',
        name: 'Zalando2'
    },
]

export default customerIdNamesPairs
