import React from 'react'
import { useParams } from 'react-router-dom'
import Header from './Header'

function LoggedOut() {
    const {messageCode} = useParams()
    let message = '';

    switch (+messageCode) {
        case 0:
            message = 'You have been logged out'
            break;
        case 1:
            message = 'Sorry, you could not be authenticated'
            break;
        default:
            message = 'Unknown error'
    }

    return (
        <div>
            <Header customerName={''} />
            <div><h2>{message}</h2></div>
        </div>
    )
}

export default LoggedOut
