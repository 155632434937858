import React, { useState } from 'react'
import SearchResult from './SearchResult'

function SearchResultContainer(props) {

    const [rowIds, setRowIds] = useState([])
    const [loading, setLoading] = useState(false)

    function onSelectionChange(e) {
        setRowIds(e.rowIds)
    }

    function onDownloadClick() {
        setLoading(true)
        // const ids = rowIds
        // todo: call endpoint to zip and download certificates
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    return (
        <SearchResult
            columns={props.columns}
            rows={props.rows}
            checkboxSelection={props.checkboxSelection}
            onSelectionChange={onSelectionChange}
            onDownloadClick={onDownloadClick}
            disabled={rowIds.length === 0}
            loading={loading} />
    )
}

export default SearchResultContainer
