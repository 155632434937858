import './App.css'
import React from 'react'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Search from './components/Search'
import Splash from './components/Splash'
import LoggedOut from './components/LoggedOut'

function App() {
    return (
        <Router>
            <Switch>
                <Route exact={true} path="/search/:id" component={Search}/>
                <Route exact={true} path="/splash/:id" component={Splash}/>
                <Route exact={true} path="/logged-out/:messageCode" component={LoggedOut}/>
            </Switch>
        </Router>
    )
}

export default App
