const dataRows = [
    {
        id: 1,
        skuNO: 'XUUBY-5Y',
        purchaseOrder: 'ZLPO36251',
        description: 'EV4_SS20_2-1-D_016',
        order: '',
        line: '',
        invoice: '',
        brandName: '',
        certificateName: 'PRJ807164/01303519'
    },
    {
        id: 2,
        skuNO: 'XUUBY-5Y',
        purchaseOrder: 'ZLPO36251',
        description: 'EV4_SS20_2-1-D_016',
        order: '',
        line: '',
        invoice: '',
        brandName: '',
        certificateName: 'PRJ807164/01303519'
    },
    {
        id: 3,
        skuNO: 'XUUBY-5Y',
        purchaseOrder: 'ZLPO36251',
        description: 'EV4_SS20_2-1-D_016',
        order: '',
        line: '',
        invoice: '',
        brandName: '',
        certificateName: 'PRJ807164/01303519'
    },
]

export default dataRows
