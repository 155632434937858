import React, { useState } from 'react'
import FormComponent from './FormComponent'

function FormContainer(props) {
    const [skuNO, setSkuNO] = useState('')
    const [purchaseOrder, setPurchaseOrder] = useState('')
    const [description, setDescription] = useState('')

    return (
        <FormComponent
            skuNO={skuNO}
            purchaseOrder={purchaseOrder}
            description={description}
            setSkuNO={setSkuNO}
            setPurchaseOrder={setPurchaseOrder}
            setDescription={setDescription}
            handleSearchClick={props.handleSearchClick}
            loading={props.loading} />
    )
}

export default FormContainer
