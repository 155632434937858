const dataColumns = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'skuNO', headerName: 'SKU NO', width: 120},
    {field: 'purchaseOrder', headerName: 'PURCHASE ORDER #', width: 190},
    {field: 'description', headerName: 'DESCRIPTION', width: 190},
    {field: 'order', headerName: 'Our Order #', width: 130},
    {field: 'line', headerName: 'Our Line #', width: 130},
    {field: 'invoice', headerName: 'Our Invoice #', width: 130},
    {field: 'brandName', headerName: 'Brand Name #', width: 130},
    {field: 'certificateName', headerName: 'Certificate Name #', width: 190},
]

export default dataColumns
